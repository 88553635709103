'use client'

import React, { ComponentType } from 'react'
import { NewsBox, NewsBoxProps } from 'components/news/NewsBox'
import { Heading } from 'components/ui/text/Heading'

type NewsWidgetProps = NewsBoxProps & {
  title: string
  ad?: { imgSrc: string; path?: string }
}

const NewsWidget: ComponentType<NewsWidgetProps> = ({
  title,
  news,
  highlight,
  tagSlugs,
  fast,
  showMoreButton,
  additionalButtons,
  priority
}) =>
  news.length ? (
    <>
      <section className="container-white">
        <div className="flex items-end justify-between items-start space-x-1 w-full mb-4">
          <Heading level={2} size="lg" border className="w-full mb-0">
            {title}
          </Heading>{' '}
          {/* {tagSlugs === 'olympia-2024' && (
            <Link href="https://www.mitsubishi-motors.de/" target="_blank">
              <Image
                className="w-40 h-auto pl-4 -mb-1 -mt-2 flex-shrink-0"
                objectFit="contain"
                src={imagePath('mitsubishi-banner.png', 'partners')}
                alt="Mitsubishi Logo"
              />
            </Link>
          )} */}
          {/* {(tagSlugs === 'nationalteams' ||
            tagSlugs === 'wm-2023' ||
            tagSlugs === 'u21-wm-2023') && (
            <Link href={HartingPath()}>
              <Image
                className="w-16 h-auto -mb-2.5 flex-shrink-0"
                objectFit="contain"
                src={imagePath('harting-logo-full.svg', 'partners')}
                alt="Harting Logo"
              />
            </Link>
          )} */}
        </div>

        <NewsBox
          news={news}
          highlight={highlight}
          tagSlugs={tagSlugs}
          fast={fast}
          showMoreButton={showMoreButton}
          priority={priority}
          additionalButtons={additionalButtons}
        />
      </section>
    </>
  ) : null

export default NewsWidget
